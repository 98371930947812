import React, { useState, useEffect } from 'react';
import './App.css';
import { QrReader } from 'react-qr-reader';
import { ring } from 'ldrs'

ring.register()

// Default values shown


const ID_LENGTH = 6;

const FormItem = function (props) {
  const { code, label, value } = props;
  return (
    <div className="form-group">
      <label htmlFor={code}>{label}:</label>
      <span id={code}>{value}</span>
    </div>
  );
}

function App() {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [password, setPassword] = useState('');
  const [id, setId] = useState('123456');
  const [fullName, setFullName] = useState('John Doe');
  const [email, setEmail] = useState('johndoe@example.com');
  const [age, setAge] = useState(25);
  const [dietaryRestrictions, setDietaryRestrictions] = useState('None');
  const [loading, setLoading] = useState(false)
  const [idField, setIdField] = useState('');
  const [emailField, setEmailField] = useState('');
  const [ticketField, setTicketField] = useState('0');
  const [result, setResult] = useState('');
  const [bgColor, setBgColor] = useState('');
  const [sheets, setSheets] = useState([]);
  const [selectedSheet, setSelectedSheet] = useState(null); // Set initial value to null
  const [showQrReader, setShowQrReader] = useState(false);

  useEffect(() => {
    fetchSheetNames();
  }, []);

  // useEffect(() => {
  //   if (idField.length >= ID_LENGTH) {
  //     fetchData();
  //   }
  // }, [idField]);

  const openSheet = () => {
    if (selectedSheet && selectedSheet.gid) {
      window.open(`https://docs.google.com/spreadsheets/d/1S588DqIDlJpFtJeXhq1iIcJYvbPWXpfVzkkk2mzY3xg/edit#gid=${selectedSheet.gid}`);
    } else {
      window.open(`https://docs.google.com/spreadsheets/d/1S588DqIDlJpFtJeXhq1iIcJYvbPWXpfVzkkk2mzY3xg/edit#gid=0`);
    }
  }

  async function fetchSheetNames() {
    const url = `${process.env.REACT_APP_API_URL}/checkin/sheets`;
    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include the API key in the headers
        },
      });
      const data = await response.json();
      setSheets(data);
    } catch (error) {
      console.error('Error fetching sheet names:', error);
    }
  }

  async function fetchData() {
    setLoading(true);
    if (!selectedSheet) {
      alert('Please select an event before submitting.');
      return;
    }

    let searchField = idField;
    if (idField.length < ID_LENGTH) {
      searchField = emailField;
    }
    const url = `${process.env.REACT_APP_API_URL}/checkin/${selectedSheet.title}/${searchField}/${ticketField}`;
    console.log(url);

    try {
      const response = await fetch(url, {
        headers: {
          'x-api-key': process.env.REACT_APP_API_KEY, // Include the API key in the headers
        },
      });
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.message || "Something went wrong");
      }

      const data = await response.json();
      setResult(data.message);

      // Assuming the data contains the same fields as the state variables
      if (data.data) {
        setId(data.data[1]);
        setFullName(data.data[3] + " " + data.data[4]);
        setEmail(data.data[6]);
        setAge(data.data[5]);
        setDietaryRestrictions(data.data[15]);
        // reset the fields
        setEmailField('');
        setIdField('');

        // Change background color on successful check-in
        setBgColor('#4cc752');
        setTimeout(() => {
          setBgColor('');
        }, 300);
      }
    } catch (error) {
      console.log(error);
      setResult(error.message);
      setBgColor('#c74c4c');
      setTimeout(() => {
        setBgColor('');
      }, 300);
    }
    setLoading(false);

  }

  function handleLogin() {
    const emailList = process.env.REACT_APP_AUTH_PASSWORDS.split(',');

    if (emailList.includes(password)) {
      setIsAuthenticated(true);
    } else {
      alert('Incorrect password');
    }
  }

  const handleKeyPress = (e, action) => {
    if (e.key === 'Enter') {
      action();
    }
  };

  if (!isAuthenticated) {
    return (
      <div className="login-container">
        <h1 style={{ marginBottom: '0' }}>VTHacks Check In</h1>
        <h5 style={{ marginLeft: '0.2rem', marginTop: '0.3rem' }}>Organizer Access Only</h5>
        <div className="form-group">
          <div className="input-area">
            <label htmlFor="inputId">Password: </label>
            <input
              type="password"
              placeholder="Enter password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              onKeyPress={(e) => handleKeyPress(e, handleLogin)}
            />
          </div>
        </div>
        <button onClick={handleLogin}>Login</button>
      </div>
    );
  }

  return (
    <div className="container" style={{ backgroundColor: bgColor }}>
      <h1>VTHacks 12 Check-In</h1>
      <div className="form-group-2">
        <label htmlFor="sheetSelect" style={{ fontWeight: 'bold', fontSize: '1.5rem' }}>Event:</label>
        <select
          id="sheetSelect"
          value={selectedSheet ? selectedSheet.title : ''}
          onChange={(e) => {
            const selected = sheets.find(sheet => sheet.title === e.target.value);
            setSelectedSheet(selected || null);
            setTicketField(selected.tickets)
          }}
        >
          <option value="" disabled>Select an event</option>
          {sheets.map(sheet => (
            <option key={sheet.gid} value={sheet.title}>
              {sheet.title}
            </option>
          ))}
        </select>
        <button className="open-button" onClick={openSheet} disabled={!selectedSheet}>Open sheet</button>
      </div>
      <div className="form-group">
        <div className="input-area">
          <label style={{ fontWeight: 'bold', fontSize: '1.3rem', marginRight: '0.2rem' }}>Tickets:</label>
          <input disabled
            type="text"
            id="ticket"
            value={ticketField}
            onChange={e => setTicketField(e.target.value)}
          />
        </div>
      </div>

      <FormItem code="id" label="ID" value={id} />
      <FormItem code="fullName" label="Full Name" value={fullName} />
      <FormItem code="email" label="Email" value={email} />
      <FormItem code="age" label="Age" value={age} />
      <FormItem code="dietaryRestrictions" label="Dietary Restrictions" value={dietaryRestrictions} />
      {showQrReader && (
        <div className="qr-reader">
          <QrReader
            key="environment"
            constraints={{ facingMode: 'environment' }}
            onResult={(result, error) => {
              if (!!result) {
                console.log(result);
                setIdField(result?.text);
              }

              if (!!error) {
                console.info(error);
              }
            }}
            style={{ width: '100%' }}
            scanDelay={5000}
          />
        </div>
      )}
      <div className="form-group">
        <div className="input-area">
          <label htmlFor="inputId">ID: </label>
          <input
            type="text"
            id="inputId"
            value={idField}
            onChange={(e) => setIdField(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e, fetchData)}
          />
        </div>
        <div className="input-area email-input">
          <label htmlFor="inputEmail">Email: </label>
          <input
            type="email"
            id="inputEmail"
            value={emailField}
            onChange={e => setEmailField(e.target.value)}
            onKeyPress={(e) => handleKeyPress(e, fetchData)}
          />
        </div>
      </div>
      <div>

        <button
          type="submit"
          className="submit-button"
          onClick={fetchData}
          disabled={!selectedSheet} // Disable submit button until an event is selected
        >
          {loading ? (<l-ring
            size="25"
            stroke="3"
            bg-opacity="0"
            speed="2"
            color="white"
          ></l-ring>) : ("Submit")}
        </button>
      </div>

      <pre id="result">{result}</pre>
    </div>
  );
}

export default App;
